import React, { useState } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PageSquarespaceListSectionUpdateNew = () => {
  const data = useStaticQuery(graphql`
    query PageSquarespaceListSectionUpdateNewQuery {
      allPrismicThemeTemplate(
        filter: {data: {platform: {eq: "Squarespace"}}},
        sort: {fields: data___date_published, order: DESC},
        limit: 100
      ) {
        edges {
          node {
            data {
              date_published
              theme_template_name
              theme_template_link {
                url
              }
              platform
              price
              theme_template_image {
                alt
                gatsbyImageData(
                  width: 500
                  height: 313
                )
              }
            }
          }
        }
      }
    }
  `)

  const [visibleItems, setVisibleItems] = useState(21)
  const doc = data.allPrismicThemeTemplate.edges

  // Function to load more items
  const handleLoadMore = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + 21)
  }

  return (
    <div className="bg-gray-100 w-full px-4 pt-0 pb-0 md:pb-24">
      <div className="w-full max-w-screen-2xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {doc.slice(0, visibleItems).map(item => {
            const themeTemplateImg = getImage(item.node.data.theme_template_image)

            return (
              <div key={item.node.data.theme_template_name} className="w-full mb-12 md:mb-0">
                <a href={item.node.data.theme_template_link.url} target='_blank' rel='noopener noreferrer'>
                  <GatsbyImage
                    image={themeTemplateImg}
                    alt={item.node.data.theme_template_image.alt}
                    className="border border-gray-300 hover:border-gray-900"
                  />
                </a>

                <h2 className="text-gray-800 hover:text-gray-900 font-medium text-lg md:text-xl hover:underline mt-6">
                  <a href={item.node.data.theme_template_link.url} target='_blank' rel='noopener noreferrer'>
                    {item.node.data.theme_template_name}
                  </a>
                </h2>

                <p className="text-gray-600 text-md font-medium uppercase mt-2">{item.node.data.price}</p>
              </div>
            )
          })}
        </div>

        {/* Load More Button */}
        {visibleItems < doc.length && (
          <div className="text-center mt-8 pb-8 md:pb-0">
            <button
              onClick={handleLoadMore}
              className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-700"
            >
              LOAD MORE
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default PageSquarespaceListSectionUpdateNew