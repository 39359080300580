import * as React from "react"

import Layout from "../components/layout/layout"
import HeroSectionNew from "../components/sections/hero-section-new"
import Seo from "../components/seo"
import PageSquarespaceListSectionUpdateNew from "../components/sections/page-squarespace-list-section-update-new"

const SquarespacePage = () => {
  return (
    <Layout>
      <Seo title="Best Squarespace Templates" />

      <HeroSectionNew subTitle="Best Squarespace Templates" />

      <PageSquarespaceListSectionUpdateNew />
    </Layout>
  )
}

export default SquarespacePage
